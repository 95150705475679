<template>
  <div class="product">
    <!-- PC -->
    <div
      class="
        productTop
        hidden-xs-only
        wow
        animate__animated animate__fadeInDown
      "
    >
      <img src="../../assets/images/swiper/swiperItem1.png" alt="" />
    </div>
    <!-- mobile -->
    <div
      class="
        mobileProductTop
        hidden-sm-and-up
        wow
        animate__animated animate__fadeInDown
      "
    >
      <img src="../../assets/images/mobile/index/index_1.png" alt="" />
    </div>
    <!-- PC -->
    <div class="solutions hidden-xs-only wow animate__animated animate__fadeInDown">
      <div class="section">
        <div class="title">
          <div class="title_one">
            <span>{{nanjieOne.engName}}</span>
          </div>
        </div>
        <div class="content" v-if="InfoOneList.length!==0">
          <div class="Item" @click="goProgramme(nanjieOne)" v-for="(item, index) in InfoOneList" :key="index">
            <img :src="url + item.picture.fileUrl" alt="" />
            <div class="itemTitle">
              <div class="itemTitle_title">
                <div class="titles">{{ item.engName }}</div>
              </div>
            </div>
            <div class="ItemContent">
              <div class="box">
                <div class="item">{{ item.title }}</div>
                <div class="itemContent">
                  <div class="titles">{{ item.engName }}</div>
                  <div class="oneContent">{{ item.solutionLists.oneContent }}</div>
                  <div class="twoContent">{{ item.solutionLists.twoContent }}</div>
                  <div>{{ item.solutionLists.threeContent }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobile -->
    <div class="mobileSolutions hidden-sm-and-up wow
        animate__animated animate__fadeInDown">
      <div class="section">
        <div class="title">
          <div class="title_one">
            <span>{{nanjieOne.engName}}</span>
          </div>
        </div>
        <div class="content" v-if="InfoOneList.length!==0">
          <div class="Item" @click="goProgramme(nanjieOne.value)" wow animate__animated animate__fadeInDown v-for="(item, index) in InfoOneList" :key="index">
            <img :src="url + item.picture.fileUrl" alt="" />
            <div class="itemTitle">
                <div class="itemTitle_title">{{ item.engName }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- PC -->
    <div class="service hidden-xs-only wow animate__animated animate__fadeInDown">
      <div class="section">
        <div class="title">
          <div class="title_one">
            <span>{{nanjieTwo.engName}}</span>
          </div>
        </div>
        <div class="content" v-if="InfoTwoList.length!==0">
          <div class="Item" @click="goProgramme(nanjieTwo)" v-for="(item, index) in InfoTwoList" :key="index">
            <img :src="url + item.picture.fileUrl" alt="" />
            <div class="itemTitle">
              <div class="itemTitle_title">
                <div class="titles">{{ item.engName }}</div>
              </div>
            </div>
            <div class="ItemContent">
              <div class="box">
                <div class="item">{{ item.title }}</div>
                <div class="itemContent">
                  <div class="titles">{{ item.engName }}</div>
                  <div class="oneContent">{{ item.solutionLists.oneContent }}</div>
                  <div class="twoContent">{{ item.solutionLists.twoContent }}</div>
                  <div>{{ item.solutionLists.threeContent }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobile -->
    <div
      class="
        mobileService
        hidden-sm-and-up
        wow
        animate__animated animate__fadeInDown
      "
    >
      <div class="section">
        <div class="title">
          <div class="title_one">
            <span>{{nanjieTwo.engName}}</span>
          </div>
        </div>
        <div class="content" v-if="InfoTwoList.length!==0">
          <div class="Item" @click="goProgramme(nanjieTwo.value)" v-for="(item, index) in InfoTwoList" :key="index">
            <img :src="url + item.picture.fileUrl" alt="" />
            <div class="itemTitle">
                <div class="itemTitle_title">{{ item.engName }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { productPage } from '../../api/management'
export default {
  data() {
    return {
      type:1,
      url:process.env.VUE_APP_WEB_URL,
      solutionListOne:
        {
          oneContent: "Enterprise DIA",
          twoContent: "Enterprise BGP",
          threeContent: "Enterprise ENAS",
        },
      solutionListTwo:
        {
          oneContent: "Enterprise MPLS-VPN",
          twoContent: "Enterprise SDWAN",
          threeContent: "Enterprise Metro Ethernet Service",
        },

      solutionListThree:
        {
          oneContent: "Financial Low Latency Circuit",
          twoContent: "Express Cloud Connect",
        },
      solutionList: [
        {
          id:1,
          imgSrc: require("../../assets/images/product/index_1.png"),
          title: "Internet Service",
          oneContent: "Enterprise DIA",
          twoContent: "Enterprise BGP",
          threeContent: "Enterprise ENAS",
        },
        {
          id:2,
          imgSrc: require("../../assets/images/product/index_2.png"),
          title: "Private Network Service",
          oneContent: "Enterprise MPLS-VPN",
          twoContent: "Enterprise SDWAN",
          threeContent: "Enterprise Metro Ethernet Service",
        },
        {
          id:3,
          imgSrc: require("../../assets/images/product/index_3.png"),
          title: "Express Routing Service",
          oneContent: "Financial Low Latency Circuit",
          twoContent: "Express Cloud Connect",
        },
      ],
      mobileSolutionList: [
        {
          id:1,
          imgSrc: require("../../assets/images/mobile/product/index_1.png"),
          title: "Internet Service",
          oneContent: "Enterprise DIA",
          twoContent: "Enterprise BGP",
          threeContent: "Enterprise ENAS",
        },
        {
          id:2,
          imgSrc: require("../../assets/images/mobile/product/index_2.png"),
          title: "Private Network Service",
          oneContent: "Enterprise MPLS-VPN",
          twoContent: "Enterprise SDWAN",
          threeContent: "Enterprise Metro Ethernet Service",
        },
        {
          id:3,
          imgSrc: require("../../assets/images/mobile/product/index_3.png"),
          title: "Express Routing Service",
          oneContent: "Financial Low Latency Circuit",
          twoContent: "Express Cloud Connect",
        },
      ],
      serviceListOnes:
        {
          oneContent: "DC Collocation",
          twoContent: "EDAS",
          threeContent: "Modular Data Center",
        },
      serviceListTwos:
        {
          oneContent: "Cloud Hosting",
          twoContent: "Multi-Cloud Management",
          threeContent: "Hybrid Cloud Service",
        },

      serviceListThrees:
        {
          oneContent: "5G Communication Base Station Design and Build",
          twoContent: "EdgeSystem Integration",
        },
      serviceList: [
        {
          id:1,
          imgSrc: require("../../assets/images/product/index_4.png"),
          title: "Datacenter Service",
          oneContent: "DC Collocation",
          twoContent: "EDAS",
          threeContent: "Modular Data Center",
        },
        {
          id:2,
          imgSrc: require("../../assets/images/product/index_5.png"),
          title: "Cloud Management Service",
          oneContent: "Cloud Hosting",
          twoContent: "Multi-Cloud Management",
          threeContent: "Hybrid Cloud Service",
        },
        {
          id:3,
          imgSrc: require("../../assets/images/product/index_6.png"),
          title: "Industry Solutions",
          oneContent: "5G Communication Base Station Design and Build",
          twoContent: "EdgeSystem Integration",
        },
      ],
      mobileServiceList: [
        {
          id:1,
          imgSrc: require("../../assets/images/mobile/product/index_4.png"),
          title: "Datacenter Service",
          oneContent: "DC Collocation",
          twoContent: "EDAS",
          threeContent: "Modular Data Center",
        },
        {
          id:2,
          imgSrc: require("../../assets/images/mobile/product/index_5.png"),
          title: "Cloud Management Service",
          oneContent: "Cloud Hosting",
          twoContent: "Multi-Cloud Management",
          threeContent: "Hybrid Cloud Service",
        },
        {
          id:3,
          imgSrc: require("../../assets/images/mobile/product/index_6.png"),
          title: "Industry Solutions",
          oneContent: "5G Communication Base Station Design and Build",
          twoContent: "EdgeSystem Integration",
        },
      ],

      InfoOne:{
        current:1,
        pageSize:10,
        categoryId:'',
        display:true
      },
      InfoOneList:[],
      InfoTwo:{
        current:1,
        pageSize:10,
        categoryId:'',
        display:true
      },
      InfoTwoList:[],
      nanjieOne:{},
      nanjieTwo:{}
    };
  },
  created() {
    // 产品的分类
    this.getCategoryTreeList()
  },
  mounted() {
    new this.$wow.WOW().init();
  },
  methods:{
    // 产品分类
    getCategoryTreeList() {
      axios({
        method: "post",
        url: this.url+`/category/treeList/`+this.type,
      }).then(res => {
        //  this.catagoryList = res.data
        this.nanjieOne = res.data[0]
        this.nanjieTwo = res.data[1]
        this.InfoOne.categoryId = this.nanjieOne.value
        this.getProductPageOne()
        this.InfoTwo.categoryId = this.nanjieTwo.value
        this.getProductPageTwo()
      })
    },
    // 产品列表
    async getProductPageOne() {
      const res = await productPage(this.InfoOne) 
      // this.InfoOneList = res.data.records.reverse()
      let listOne = res.data.records.reverse()
      listOne.forEach(item => {
        if(item.name=="互联网接入服务") {
          item.solutionLists = this.solutionListOne
        } else if(item.name=='虚拟专用网服务') {
          item.solutionLists = this.solutionListTwo
        } else if(item.name=='低延时专网服务') {
          item.solutionLists = this.solutionListThree
        } 
      });
      this.InfoOneList = listOne
    },
    async getProductPageTwo() {
      const res = await productPage(this.InfoTwo) 
      // this.InfoTwoList = res.data.records.reverse()
      let listOne = res.data.records.reverse()
      listOne.forEach(item => {
        if(item.name=="数据中心服务") {
          item.solutionLists = this.serviceListOnes
        } else if(item.name=='云管理服务') {
          item.solutionLists = this.serviceListTwos
        } else if(item.name=='行业解决方案') {
          item.solutionLists = this.serviceListThrees
        } 
      });
      this.InfoTwoList = listOne
    },
    goProgramme(item) {
      let id = item.value
      this.$router.push({path:'/programmeEn',query:{id:id}})
    }
  }
};
</script>
<style lang="scss">
.product {
  padding-top: 92px;
  .productTop {
    img {
      width: 100%;
      height: 482px;
    }
  }
  .solutions {
    padding-bottom: 82px;
    .section {
      .title {
        padding: 82px 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        .title_one {
          span {
            font-size: 36px;
            display: inline-block;
            background-image: linear-gradient(
              to right,
              #25a9e0,
              #187aba
            ); //最后三行为文字渐变CSS
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        .title_two {
          margin-top: 8px;
          span {
            font-size: 16px;
            display: inline-block;
            background-image: linear-gradient(
              to right,
              #25a9e0,
              #187aba
            ); //最后三行为文字渐变CSS
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
      .content {
        display: flex;
        justify-content: space-between;
        .Item {
          width: 342px;
          height: 325px;
          position: relative;
          overflow: hidden;
          cursor: pointer;
          img {
            width: 342px;
            height: 325px;
          }
          .itemTitle {
            position: absolute;
            height: 52px;
            left: 0;
            top: 100%;
            width: 342px;
            margin-top: -51px;
            text-align: center;
            transition: all 0.15s linear;
            background: linear-gradient(to right, #25a9e0, #187aba);
            opacity: .8;
            .itemTitle_title {
              position: relative;
              .titles{
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                color: #fff;
                font-size: 18px;
                line-height: 52px;
              }
            }
          }
          .ItemContent {
            transition: all 0.4s linear;
            position: relative;
            width: 100%;
            height: 325px;
            .box {
              text-align: center;
              font-size: 16px;
              position: absolute;
              color: #fff;
              left: 0;
              top: -4px;
              width: 100%;
              height: 325px;
              background:rgba(37, 169, 224,0.6);
              .item {
                margin: 60px 0 30px 0;
              }
              .itemContent {
                height: 210px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
              }
            }
          }
        }
        .Item:hover .ItemContent {
          transform: translateY(-325px);
          animation-play-state: running;
        }
        .Item:hover .itemTitle {
          transform: translateY(51px);
          animation-play-state: running;
        }
      }
    }
  }
  .service {
    padding-bottom: 82px;
    .section {
      .title {
        padding-bottom: 82px;
        text-align: center;
        display: flex;
        flex-direction: column;
        .title_one {
          span {
            font-size: 36px;
            display: inline-block;
            background-image: linear-gradient(
              to right,
              #25a9e0,
              #187aba
            ); //最后三行为文字渐变CSS
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        .title_two {
          margin-top: 8px;
          span {
            font-size: 16px;
            display: inline-block;
            background-image: linear-gradient(
              to right,
              #25a9e0,
              #187aba
            ); //最后三行为文字渐变CSS
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
      .content {
        display: flex;
        justify-content: space-between;
        .Item {
          width: 342px;
          height: 325px;
          position: relative;
          overflow: hidden;
          cursor: pointer;
          img {
            width: 342px;
            height: 325px;
          }
          .itemTitle {
            opacity: .8;
            position: absolute;
            height: 52px;
            left: 0;
            top: 100%;
            width: 342px;
            margin-top: -51px;
            text-align: center;
            transition: all 0.15s linear;
            background: linear-gradient(to right, #25a9e0, #187aba);
            .itemTitle_title {
              position: relative;
              .titles{
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                color: #fff;
                font-size: 18px;
                line-height: 52px;
              }
            }
          }
          .ItemContent {
            transition: all 0.4s linear;
            position: relative;
            width: 100%;
            height: 325px;
            .box {
              text-align: center;
              font-size: 16px;
              position: absolute;
              color: #fff;
              left: 0;
              top: -4px;
              width: 100%;
              height: 325px;
              background:rgba(37, 169, 224,0.6);
              .item {
                margin: 60px 0 30px 0;
              }
              .itemContent {
                height: 210px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
              }
            }
          }
        }
        .Item:hover .ItemContent {
          transform: translateY(-325px);
          animation-play-state: running;
        }
        .Item:hover .itemTitle {
          transform: translateY(51px);
          animation-play-state: running;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .product {
    padding-top: 0;
    .mobileProductTop {
      img {
        height: 4rem;
      }
    }
    .mobileSolutions {
      .section {
        .title {
          text-align: center;
          padding: 0.6rem 0 0.4rem 0;
          .title_one {
            span {
              font-size: 0.5rem;
              display: inline-block;
              background-image: linear-gradient(
                to right,
                #25a9e0,
                #187aba
              ); //最后三行为文字渐变CSS
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
          .title_two {
            span {
              font-size: 0.2rem;
              display: inline-block;
              background-image: linear-gradient(
                to right,
                #25a9e0,
                #187aba
              ); //最后三行为文字渐变CSS
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
        .content {
          display: flex;
          flex-direction: column;
          align-items: center;
          .Item {
            margin-bottom: .5rem;
            position: relative;
            height: 3rem;
            width: 100%;
            img {
              height: 3rem;
              width: 100%;
              vertical-align: bottom;
            }
            .itemTitle {
              position: absolute;
              left: 0;
              top: 100%;
              width: 100%;
              opacity: 0.8;
              margin-top: -.8rem;
              text-align: center;
              transition: all 0.15s linear;
              background: linear-gradient(to right, #25a9e0, #187aba);
              .itemTitle_title {
                color: #fff;
                font-size: 18px;
                line-height: .8rem;
              }
            }
          }
        }
      }
    }
    .mobileService {
      .section {
        .title {
          text-align: center;
          padding: 0.6rem 0 0.4rem 0;
          .title_one {
            span {
              font-size: 0.5rem;
              display: inline-block;
              background-image: linear-gradient(
                to right,
                #25a9e0,
                #187aba
              ); //最后三行为文字渐变CSS
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
          .title_two {
            span {
              font-size: 0.2rem;
              display: inline-block;
              background-image: linear-gradient(
                to right,
                #25a9e0,
                #187aba
              ); //最后三行为文字渐变CSS
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
        .content {
          display: flex;
          flex-direction: column;
          align-items: center;
          .Item {
            margin-bottom: .5rem;
            position: relative;
            height: 3rem;
            width: 100%;
            img {
              height: 3rem;
              width: 100%;
              vertical-align: bottom;
            }
            .itemTitle {
              position: absolute;
              left: 0;
              top: 100%;
              width: 100%;
              opacity: 0.8;
              margin-top: -.8rem;
              text-align: center;
              transition: all 0.15s linear;
              background: linear-gradient(to right, #25a9e0, #187aba);
              .itemTitle_title {
                color: #fff;
                font-size: 18px;
                line-height: .8rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>